<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">客服設定</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <div class="title">分店1客服設定</div>
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record mt-2">
          <div class="input rounded">
            <!-- <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div> -->
            <span class="fM nowrap gray label">
              客服專線
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="datas.idNumber"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <!-- <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div> -->
            <span class="fM nowrap gray label">
              臉書專頁
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="datas.title"
            />
          </div>
        </div>

        <div class="flex ml-1 mt-2 text-gray-500">
          <input
            class="mr-1"
            type="checkbox"
            :name="checkbox"
            :value="status"
            v-model="status"
          />
          <div>所有分店使用相同的設定</div>
        </div>
      </div>

      <div class="title">分店2客服設定</div>
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record mt-2">
          <div class="input rounded">
            <!-- <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div> -->
            <span class="fM nowrap gray label">
              客服專線
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="datas.idNumber"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <!-- <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div> -->
            <span class="fM nowrap gray label">
              臉書專頁
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="datas.title"
            />
          </div>
        </div>
      </div>

      <div class="buttons records flexV width padding mt-2">
        <button @click="goBack()">儲存並關閉</button>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      latestReadAt: 0,
      status: false,
      datas: {
        idNumber: "0987654321",
        title: "https://www.facebook.com/mockup",
        type: "零售業",
        phone: "0987654321",
        address: "10491台北市中山區中山北路二段106-2號 號 9 樓",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 99px !important;
}

button {
  background-color: white !important;
  border: 1px solid var(--c01) !important;
  color: var(--c01);
  border-radius: 20px !important;
  height: 51px !important;
}

.label {
  width: 170px;
}

.title {
  margin: 24px auto 12px auto;
  font-size: 1.125rem;
  font-weight: 500;
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
