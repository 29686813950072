<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">特店管理後台</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="pl-5 mt-2 flex justify-between">
          <div>分店一覽</div>
          <div class="primary" @click="add()">
            新增分店
            <font-awesome-icon
                icon="fa-solid fa-plus"
            ></font-awesome-icon>
          </div>
        </div>
        <div class="record" style="background-color : var(--c002);">
          <div class="flexH width relative mb-1">
            <div class="gap-1 mr-1">
              <img
                style="width: 52px; height: 52px"
                src="@/assets/icon/dev/02.png"
                alt=""
              />
            </div>
            <div class="flexV width line-height leading-8">
              <div class="fS text-white">
                <img
                  src="@/assets/icon/document.svg"
                  alt=""
                />
                商店類型
              </div>
              <div>
                <div class="fL text-white">特約商店特約商店名稱</div>
                <div class="fS text-white">0987654321</div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="flex justify-end items-center">
              <button class="mr-1 button-outline" @click="edit()">
                編輯
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="records flexV width padding">
        <div class="pl-5">分店功能</div>
        <div class="flex justify-around">
          <div class="record mt-2 flex justify-around items">
            <a class="text-center" href="/store-management/record">
              <img src="@/assets/icon/store01.svg" alt="" />
              <div>入帳紀錄</div>
            </a>
          </div>
          <div class="record mt-2 flex justify-around items">
            <a class="text-center" href="/store-management/payment-record">
              <img src="@/assets/icon/store02.svg" alt="" />
              <div>請款紀錄</div>
            </a>
          </div>
          <div class="record mt-2 flex justify-around items">
            <!-- href="/store-management/reserve" -->
            <a class="text-center">
              <img src="@/assets/icon/store03.svg" alt="" />
              <div>活動產碼</div>
            </a>
          </div>
        </div>
      </div>

      <div class="records flexV width padding">
        <div class="pl-5">系統設定</div>
        <div class="flex justify-around">
          <div class="record mt-2 flex justify-around items">
            <a class="text-center" href="/store-management/service-setting">
              <img src="@/assets/icon/store04.svg" alt="" />
              <div>客服設定</div>
            </a>
          </div>
          <div class="record mt-2 flex justify-around items">
            <a class="text-center" href="/store-management/access-setting">
              <img src="@/assets/icon/store05.svg" alt="" />
              <div>權限管理</div>
            </a>
          </div>
          <div class="record mt-2 flex justify-around items">
            <a class="text-center" href="/store-management/notify-setting">
              <img src="@/assets/icon/store06.svg" alt="" />
              <div>通知設定</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      latestReadAt: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/store-management/add");
    },
    edit(id) {
      this.$router.push(`/store-management/edit/${id}`);
    },
    qrcode() {
      // this.$router.push("/store-management/qrcode");
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
  margin-top: 12px !important;
}

.items {
  width: 109px;
  padding: 8px !important;
}

button {
  color: white;
  border-radius: 16px !important;
}

.button-outline {
  color: white !important;
  border-radius: 16px !important;
  background-color: transparent;
  border: 1px solid white;
  width: 48px;
  height: 24px;
  font-size: 0.75rem;
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
