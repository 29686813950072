<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">交易明細</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div
        v-if="datas.length !== 0 && status === 1"
        class="records flexV width padding mt-3"
      >
        <div class="flex justify-center">
          <div class="primary mr-3">
            <span class="fS font-normal cNeutra500 font-sm flex items-center">
              <img class="mr-1" src="@/assets/icon/coin.svg" alt="" />
              <div style="font-size: 1.5rem">1000</div>
            </span>
          </div>
          <div class="primary">
            <span class="fS font-normal cNeutra500 font-sm flex items-center">
              <img class="mr-1" src="@/assets/icon/coin.svg" alt="" />
              <div style="font-size: 1.5rem">200</div>
            </span>
          </div>
        </div>

        <div class="flex justify-between">
          <div>交易型態</div>
          <div>消費</div>
        </div>

        <div class="flex justify-between">
          <div>交易時間</div>
          <div>2025/01/01 12:00:59</div>
        </div>

        <div class="flex justify-between">
          <div>消費者姓名</div>
          <div>王＊明</div>
        </div>

        <div class="flex justify-between">
          <div>消費者電話</div>
          <div>0987***321</div>
        </div>

        <div class="flex justify-between">
          <div>交易特店</div>
          <div>商家名稱</div>
        </div>

        <div class="flex justify-between">
          <div>交易編號</div>
          <div>AS1234567890</div>
        </div>

        <div class="flex justify-between">
          <div>支付方式</div>
          <div>信用卡</div>
        </div>

        <div class="flex justify-between">
          <div>交易狀態</div>
          <div>已付款</div>
        </div>
      </div>

      <div class="text-center flex gap-6 item-center py-4" v-else>
        <img
          width="160"
          height="160"
          src="@/assets/icon/empty-notification.svg"
        />
        <div class="fL font-medium cNeutral500 text-center">
          很抱歉，目前尚未有可兌換的折抵券
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      latestReadAt: 0,
      status: 1,
      datas: [
        {
          id: 4,
          title: "王＊明 - 0987***321 (消費)",
          company: "2025/01/01  12:00:59",
          pay: "260",
        },
        {
          id: 5,
          title: "王＊明 - 0987***321 (消費)",
          company: "2025/01/01  12:00:59",
          pay: "200",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    onSnapShot() {
      let collection = db.collection(
        `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      );
      // doesn't use change to vuefire bind
      collection.onSnapshot((snapshot) => {
        this.notifications = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.notifications.push(
            Object.assign({}, data, {
              docId: doc.id,
              unread: data.unread && data.ts > this.latestReadAt,
            })
          );
        });
        this.notifications.sort(this.compare);
        console.log("this.notifications: ", this.notifications);
        this.getLatestReadTime();
      });
    },
    getLatestReadTime() {
      return db
        .collection(
          `notifications/yulin/recipients/${this.user["userId"]}/readTopics`
        )
        .doc(this.user.user.firestoreChannelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          this.latestReadAt = snapshot.data().latestReadAt;
        });
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach((noti) => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },
    detail(item) {
      console.log(item);
      this.$router.push(`/store-management/detail`);
    },
    detail2(item) {
      console.log(item);
      this.$router.push(`/store-management/detail`);
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.main {
  margin: 0 32px !important;
  line-height: 3;
  color: gray;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

img {
  width: 24 !important;
  height: 24px !important;
}
</style>
